import React from "react";
import StartLearningComp from "../components/dashboardComp/StartLearningComp";
import DashboardComp from '../components/dashboardComp/index'

const Dashboard = () => {
  return (
    <div>
      {/* <StartLearningComp /> */}
      <DashboardComp/>
    </div>
  );
};

export default Dashboard;
