import React from "react";
import AlertModal from "../commonComponents/AlertModal";
import main_Logo from "../../assets/images/main_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setQuestionNumber } from "../../Store";

const ExamSideBarComp = ({
  modalOpen,
  setModalOpen,
  allQuestionsList,
}) => {
  const dispatch = useDispatch();
  const questionNumber = useSelector((val) => val.questionNumber);

  const getQuestionNumberHandler = (getQ) => {
    dispatch(setQuestionNumber(getQ - 1));
  };
  return (
    <section
      className="w-1/5 flex flex-col justify-between overflow-y-scroll pb-20"
      style={{
        background: "rgba(226, 17, 133, 0.09)",
      }}
    >
      <div>
        <img
          src={main_Logo}
          className=" cursor-pointer h-11 w-11/12 m-auto mt-5"
        />
        <div
          style={{
            background: "rgba(103, 44, 213, 0.15)",
          }}
          className="mt-4 h-10 text-[#672CD5] text-center leading-10 font-semibold"
        >
          QUESTIONS
        </div>

        <article className=" w-4/5 m-auto grid grid-cols-4  gap-4 mt-10">
          {allQuestionsList.map((item, i) => {
            return (
              <div
                key={item._id}
                className={` ${
                  item.questionNumber === questionNumber + 1
                    ? " rounded-md "
                    : " rounded-md text-grdient-border"
                } w-8 h-7 text-center leading-7 font-medium cursor-pointer`}
                onClick={() => getQuestionNumberHandler(item.questionNumber)}
              >
                <div
                  className={`${
                    item.questionNumber === questionNumber + 1
                      ? "bg-gradient-to-r from-purple-700 to-pink-500 text-white"
                      : "bg-white h-full w-full"
                  } rounded-md `}
                >
                  {item.questionNumber}
                </div>
              </div>
            );
          })}
        </article>

        <article className=" w-4/5 m-auto mt-16 ">
          <div className="flex items-center gap-5">
            <p className="bg-[#2FA450] rounded-md w-8 h-7 text-center text-white leading-7 font-medium cursor-pointer">
              0
            </p>
            <p className=" h-7 text-center  leading-7 font-medium ">Answered</p>
          </div>
          <div className="flex items-center gap-5 mt-5">
            <p className="bg-[#C0392B] rounded-md w-8 h-7 text-center text-white leading-7 font-medium cursor-pointer">
              0
            </p>
            <p className=" h-7 text-center  leading-7 font-medium ">
              Not Answered
            </p>
          </div>
          <div className="flex items-center gap-5 mt-5">
            <p className="bg-white rounded-md w-8 h-7 text-center text-black leading-7 font-medium cursor-pointer">
              0
            </p>
            <p className=" h-7 text-center  leading-7 font-medium ">
              Not Visited
            </p>
          </div>
        </article>
      </div>

      <div className="absolute bottom-0 flex justify-center items-center mb-8 mt-20 w-[20%]">
        <button
          className=" px-6 h-8 flex-shrink-0 rounded-2xl bg-gradient-to-r from-purple-700 to-pink-500 text-white text-center font-montserrat text-sm font-bold"
          onClick={() => setModalOpen(true)}
        >
          Submit Test
        </button>
      </div>
      {modalOpen && <AlertModal setModalOpen={setModalOpen} />}
    </section>
  );
};

export default ExamSideBarComp;
