import React from "react";
import StartLearningComp from "./StartLearningComp";
import DashboardActivity from "./DashboardActivity";
import Graph from "../GraphComp/Graph";

const DashboardComp = () => {
  return (
    <div className="grid grid-cols-3 gap-10">
      <section className="col-span-2 ">
        <StartLearningComp />
        <div className="mt-10 px-10 py-5 shadow-md rounded-xl">
          <Graph />
        </div>
      </section>

      <section className="col-span-1 ">
        <DashboardActivity />
      </section>
    </div>
  );
};

export default DashboardComp;
