const baseUrls = {
  "vas-admin-panel.onrender.com": "https://vas-admin-panel.onrender.com",
  "sea-lion-app-jxriy.ondigitalocean.app":
    "https://seal-app-lkrxg.ondigitalocean.app",
  "localhost:4000": "https://seal-app-lkrxg.ondigitalocean.app",
};

const currentHostname = window.location.host;
module.exports = {
  baseurl: baseUrls[currentHostname] || "http://localhost:4000",
};
