import React, { useState } from "react";

const RangeSlider = ({ startRange, endRange, steps }) => {
  const values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleInputChange = (e) => {
    setCurrentStepIndex(e.currentTarget.value);
  };

  const gradientPercentage = (currentStepIndex / 10) * 100;

  return (
    <div className="text-center w-11/12">
      <input
        onInput={handleInputChange}
        type="range"
        min="0"
        value={currentStepIndex}
        max={endRange}
        step={steps}
        list="tick-list"
        className="w-full cursor-pointer range-slider"
      />

      <div className="flex justify-between ml-0.5">
        {[...Array(endRange + 1)].map((value, index) => (
          <label
            key={index}
            htmlFor="range"
            className={`text-sm text-gray-500 ${
              index === currentStepIndex ? "font-bold" : ""
            }`}
          >
            {index % steps === 0 ? index : null}
          </label>
        ))}
      </div>

      <style>
        {`
          .range-slider {
            -webkit-appearance: none;
            width: 100%;
            height: 3px;
            background: linear-gradient(to right, #672CD5, #E21185 ${gradientPercentage}%, #777777 ${gradientPercentage}%, #777777 100%);
            outline: none;
            border: none;
            border-radius:10px;
          }
          
          .range-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 14px;
            height: 14px;
            background: linear-gradient(to right, #672CD5, #E21185);
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
          }
        `}
      </style>
    </div>
  );
};

export default RangeSlider;
