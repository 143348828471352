import { configureStore, createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "store",
  initialState: {
    heading: "",
    menuOpen: "",
    questionNumber: 0,
  },
  reducers: {
    setHeading(state, action) {
      state.heading = action.payload;
    },

    setOpenMenu(state, action) {
      state.menuOpen = action.payload;
    },
    setQuestionNumber(state, action) {
      state.questionNumber = action.payload;
    },
  },
});

export const { setHeading, setQuestionNumber, setOpenMenu } =
  counterSlice.actions;

const store = configureStore({
  reducer: counterSlice.reducer,
});

export default store;
