import React from "react";
import ModuleList from "../components/moduleComp/ModuleList";

const MyModulePage = () => {
  return (
    <div>
      <ModuleList />
    </div>
  );
};

export default MyModulePage;
