import React, { useState, useEffect } from "react";
import ExamSideBarComp from "./ExamSideBarComp";
import ExamQuestionComp from "./ExamQuestionComp";
import BaseURL from "../../BaseURL/BaseURL";
import axios from "axios";
import Loader from "../commonComponents/Loader";

const ExamComponent = ({ moduleId, duration, moduleAttemptId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1 * 60); // 90 minutes in seconds
  const authToken = localStorage.getItem("authToken");
  const [allQuestionsList, setAllQuestionsList] = useState([]);
  const [apiLoaded, setApiLoaded] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    // console.log("timeLeft", formatTime(timeLeft));
    // console.log("check", formatTime(timeLeft) === "00:00");
    if (formatTime(timeLeft) === "00:00") {
      callApi();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!authToken) {
      console.error("No auth token available.");
      return;
    }

    axios
      .get(
        `${BaseURL.baseurl}/api/moduleAttempt/getModuleAttemptQuestionsList?moduleAttemptID=${moduleAttemptId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.moduleQuestions.length !== 0) {
          setAllQuestionsList(res.data.moduleQuestions);
          setApiLoaded(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const callApi = () => {
    console.log("Hit Api");
  };

  const countDownTimer = formatTime(timeLeft);

  return (
    <div className="flex  h-[100vh] w-[100vw] fixed">
      {apiLoaded ? (
        <div className="h-[100vh] w-[100vw] fixed flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <ExamSideBarComp
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            allQuestionsList={allQuestionsList}
          />
          <ExamQuestionComp
            countDownTimer={countDownTimer}
            allQuestionsList={allQuestionsList}
          />
        </>
      )}
    </div>
  );
};

export default ExamComponent;
