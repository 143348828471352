import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AlertModal = ({ setModalOpen }) => {
  return (
    <div>
      <div
        className={`z-50 h-[100vh] w-[100vw] fixed top-0 right-0 flex justify-center items-center`}
      >
        <div className=" flex items-center justify-center z-50  mt-28">
          <div className="z-50 bg-white p-8  shadow-2xl w-[500px] rounded-2xl">
            <div className="flex justify-between items-center mb-4">
              <div className="font-semibold text-fs22">Alert</div>

              <button
                className="  px-3 py-1 bg-gradient-to-r from-violet-700 to-pink-600 rounded-md text-white"
                onClick={() => setModalOpen(false)}
              >
                X
              </button>
            </div>

            <section className="text-start">
              <p className=" text-base mt-2">
                Are you sure you want to submit this assignment?
              </p>

              <div className="flex gap-3 items-center justify-between mt-8">
                <div className="flex items-center gap-1">
                  <p className="bg-[#2FA450] rounded-md w-8 h-7 text-center text-white leading-7 font-medium cursor-pointer">
                    0
                  </p>
                  <p className=" h-7 text-center  leading-7 font-medium ">
                    Answered
                  </p>
                </div>
                <div className="flex items-center gap-1 ">
                  <p className="bg-[#C0392B] rounded-md w-8 h-7 text-center text-white leading-7 font-medium cursor-pointer">
                    0
                  </p>
                  <p className=" h-7 text-center  leading-7 font-medium ">
                    Not Answered
                  </p>
                </div>
                <div className="flex items-center gap-1 ">
                  <p className="bg-white rounded-md w-8 h-7 text-center text-black leading-7 font-medium cursor-pointer">
                    0
                  </p>
                  <p className=" h-7 text-center  leading-7 font-medium ">
                    Not Visited
                  </p>
                </div>
              </div>

              <div className="flex justify-center items-center mt-2">
                <button
                  className=" px-6 h-8 mt-5 flex-shrink-0 rounded-2xl bg-gradient-to-r from-purple-700 to-pink-500 text-white text-center font-montserrat text-sm font-bold"
                  onClick={() => setModalOpen(false)}
                >
                  Confirm Submission
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
