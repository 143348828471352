import React, { useEffect, useState } from "react";
import "../styles.css";
import Modal from "../commonComponents/Modal";
import BaseURL from "../../BaseURL/BaseURL";
import axios from "axios";
import Loader from "../commonComponents/Loader";

const ModuleList = () => {
  const [moduleListData, setModuleListData] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const [apiLoaded, setApiLoaded] = useState(true);

  const [moduleId, setModuleId] = useState("");
  const [moduleData, setmoduleData] = useState("");

  useEffect(() => {
    if (!authToken) {
      console.error("No auth token available.");
      return;
    }

    axios
      .get(`${BaseURL.baseurl}/api/module/getList`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        if (res.data.moduleListForClientEnrollment.length !== 0) {
          setModuleListData(res.data.moduleListForClientEnrollment);
          setApiLoaded(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const moduleDataHandler = (getModule) => {
    setModuleId(getModule._id);
    setmoduleData(getModule);
  };

  return (
    <>
      {apiLoaded ? (
        <Loader />
      ) : moduleListData.length !== 0 ? (
        <section className="grid xl:grid-cols-4 lg:grid-cols-3 mdm:grid-cols-3 gap-1 w-11/12 box-shadow p-5">
          {moduleListData.map((item, i) => {
            return (
              <div key={item._id}>
                <div
                  className="bg-white  w-full rounded-2xl px-4 pt-4 pb-0  text-center cursor-pointer"
                  onClick={() => moduleDataHandler(item)}
                >
                  <img
                    src={item.thumbnail.Location}
                    className=" rounded-xl h-36 w-full border"
                  />
                  <h3 className="font-semibold mt-4 text-sm">{item.name}</h3>
                </div>
                {item._id === moduleId ? (
                  <Modal setModuleId={setModuleId} moduleData={moduleData} />
                ) : null}
              </div>
            );
          })}
        </section>
      ) : (
        <div className="text-cneter mt-10">No Module found!!!</div>
      )}
    </>
  );
};

export default ModuleList;
