import Header from "../components/Header/Header";
import Sidebar from "./sidebar";
import "./layout.css";

function RootLayout({ children }) {
  return (
    <div className="flex fixed w-full h-hvh overflow-hidden">
      <Sidebar />
      <div className="w-full bg-white">
        <Header />
        <main className=" flex-1 p-4 overflow-y-scroll overflow-auto hide_scrollbar h-[90vh] ">
          {children}
        </main>
      </div>
    </div>
  );
}

export default RootLayout;
