import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import BaseURL from "../../BaseURL/BaseURL";
import axios from "axios";

const Modal = ({ setModuleId, moduleData }) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const [spinner, setSpinner] = useState(false);
  const openMenu = useSelector((state) => state.menuOpen);

  const hideInstructionModalHandler = () => {
    setModuleId("");
  };

  const createModuleAttemptHandler = async () => {
    setSpinner(true);
    try {
      const requestData = new URLSearchParams();
      requestData.append("moduleID", moduleData._id);

      const response = await axios.post(
        `${BaseURL.baseurl}/api/moduleAttempt/create`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.message === "Module attempt created successfully") {
        setSpinner(false);
        console.log("response.data", response.data.moduleAttempt._id);
        navigate("/start-module-exam", {
          state: {
            moduleData,
            moduleAttemptId: response.data.moduleAttempt._id,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div
        className={`z-50 h-[100vh] ${
          openMenu ? "w-[85vw]" : "w-[100vw]"
        } fixed top-0 right-0 flex justify-center items-center`}
      >
        <div className=" flex items-center justify-center z-50 w-w100p h-h100p mt-28">
          <div className="z-50 bg-white p-8  shadow-2xl w-[500px] rounded-2xl">
            <div className="flex justify-between items-center mb-4">
              <div className="font-semibold text-fs22">Intructions</div>

              <button
                className="  px-3 py-1 bg-gradient-to-r from-violet-700 to-pink-600 rounded-md text-white"
                onClick={hideInstructionModalHandler}
              >
                X
              </button>
            </div>

            <section className="text-start">
              <h3 className="font-semibold mt-4 text-sm">{moduleData.name}</h3>
              <p className="text-[#777] text-xs mt-2">
                {moduleData.instruction}
              </p>
              <h5 className="text-gradient-md text-sm italic">
                Avg Time : {moduleData.duration / 60} Minutes
              </h5>
              <div className="flex justify-center items-center mt-2">
                {moduleData.locked ? (
                  <button
                    className="px-4 h-8 flex-shrink-0 rounded-2xl bg-gradient-to-r from-purple-700 to-pink-500 text-white text-center font-montserrat text-sm font-bold"
                    onClick={hideInstructionModalHandler}
                  >
                    {moduleData.reasonForLock}
                  </button>
                ) : (
                  <button
                    className=" w-24 h-8 flex-shrink-0 rounded-2xl bg-gradient-to-r from-purple-700 to-pink-500 text-white text-center font-montserrat text-sm font-bold"
                    onClick={createModuleAttemptHandler}
                  >
                    {spinner ? <Spinner /> : "Begin"}
                  </button>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
