import React, { useState, useEffect } from "react";
// import CountryFlag from "react-country-flag";
import countryCode from "../../assets/data/CountryCode.json";

const CountryFlagSelection = ({ setCountryCode }) => {
  const countryList = countryCode;
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [flagCode, setFlagCode] = useState("+91");
  const [isEmojiLoaded, setIsEmojiLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsEmojiLoaded(true);
    }, 1000); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  const countryDropdownHandler = () => {
    setCountryDropdown(!countryDropdown);
  };

  const selectCountryHandler = (getCountry) => {
    setCountryCode(getCountry.dial_code);
    setFlagCode(getCountry.dial_code);
    setCountryDropdown(false);
  };

  return (
    <div className="relative ">
      <div
        onClick={countryDropdownHandler}
        className="cursor-pointer flex items-center gap-1"
      >
        {/* {isEmojiLoaded ? (
          <CountryFlag
            countryCode={flagCode}
            svg
            style={{
              width: "1.5em",
              height: "1.5em",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "8px",
            }}
          />
        ) : ( */}
        <span className="text-[#A098AE]">{flagCode}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="7"
          viewBox="0 0 7 4"
          fill="none"
        >
          <path
            d="M3.49735 3.24995L3.4965 3.24995C3.49639 3.24996 3.49532 3.25 3.49298 3.24897C3.49057 3.24791 3.48596 3.24529 3.48038 3.23943L1.31476 0.90722C1.30202 0.893502 1.28906 0.867477 1.28906 0.833278C1.28906 0.79908 1.30202 0.773055 1.31476 0.759337C1.32074 0.752892 1.32576 0.749997 1.3285 0.748773C1.33117 0.747583 1.33253 0.747559 1.33295 0.747559C1.33336 0.747559 1.33472 0.747583 1.33739 0.748773C1.34011 0.749988 1.34507 0.75285 1.351 0.759195C1.35104 0.759243 1.35109 0.75929 1.35113 0.759337L3.1327 2.68378L3.49906 3.07952L3.86601 2.68434L5.62768 0.787155C5.62932 0.786171 5.63062 0.785604 5.63147 0.785285C5.63319 0.784638 5.63395 0.784672 5.63414 0.784681L5.63415 0.784681C5.63433 0.784689 5.63554 0.784725 5.63802 0.785894C5.64057 0.787092 5.64516 0.789846 5.65065 0.795759C5.66226 0.808272 5.67448 0.831808 5.67561 0.863419C5.67656 0.889802 5.66941 0.912202 5.65998 0.92776L3.51377 3.23906C3.51365 3.23919 3.51353 3.23932 3.51341 3.23945C3.50788 3.24526 3.50333 3.24785 3.50093 3.24892C3.49861 3.24995 3.49753 3.24995 3.49736 3.24995L3.49735 3.24995Z"
            fill="#A098AE"
            stroke="#A098AE"
          />
        </svg>
        {/* )} */}
      </div>
      {countryDropdown && (
        <div className="absolute z-20 bg-white divide-y top-8 left-0 divide-gray-100 rounded shadow w-auto">
          <ul
            className={`py-2 text-sm text-black w-auto ${
              countryList.length > 5 ? "h-52" : null
            } overflow-y-auto`}
          >
            {countryList.map((val, i) => (
              <li
                key={val.flagCode}
                onClick={() => {
                  selectCountryHandler(val);
                }}
                className="block flex px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-400 dark:hover:text-white w-auto cursor-pointer" // Add cursor pointer style
              >
                {val.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CountryFlagSelection;
