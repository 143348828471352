import React from "react";

const DashboardActivity = () => {
  return (
    <div className="w-full h-36  bg-gradient-to-r from-purple-700 to-pink-500 rounded-2xl p-5 ">
      <div className="flex items-center justify-center gap-2  ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.20174 0.862305L10.436 2.09974L5.38013 7.16852L10.436 12.2373L9.20174 13.4747L2.91155 7.16852L9.20174 0.862305Z"
            fill="white"
          />
        </svg>
        <p className="text-white font-medium">April 2022</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
        >
          <g opacity="0.2">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.79875 13.4746L3.56445 12.2372L8.62035 7.16839L3.56445 2.09961L4.79874 0.862173L11.0889 7.16839L4.79875 13.4746Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DashboardActivity;
