import React from "react";

const StartLearningComp = () => {
  return (
    <div className="w-full p-6 grid grid-cols-8 gap-2   bg-gradient-to-r from-purple-700 to-pink-500 rounded-2xl ">
      <p className="text-white mdm:col-span-5 xl:col-span-6 flex items-center">
        Let’s start again from where you left
      </p>
      <button className="bg-white mdm:col-span-3 xl:col-span-2  py-1 rounded-xl">
        Start learning
      </button>
    </div>
  );
};

export default StartLearningComp;
