// import React from "react";
// import Login from "./components/Login/Login";
// import Landing from "./components/App_Jiva/Landing";
// import Footer from "./components/Footer/Footer";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import MailVerified from "./pages/MailVerified";
// import Register from "./components/Login/Register";
// import PhoneNoLogin from "./components/Login/PhoneNoLogin";
// import WelcomeLoginPage from "./pages/loginPages/WelcomeLoginPage";
// import TrialCode from "./TrialCode";

// function App() {
//   return (
//     <>
//       <Router>
//         <Routes>
//           <Route path="/" element={<WelcomeLoginPage />} />
//           <Route path="/register" element={<PhoneNoLogin />} />
//           <Route path="/register-form" element={<Register />} />
//           <Route path="/verify" element={<MailVerified />} />
//           <Route path="/trial" element={<TrialCode />} />
//         </Routes>
//       </Router>

//       <Landing />
//       <Footer />
//     </>
//   );
// }

// export default App;

import React, { useEffect } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import Login from "./components/Login/Login";
import Landing from "./components/App_Jiva/Landing";
import Footer from "./components/Footer/Footer";
import MailVerified from "./pages/MailVerified";
import Register from "./components/Login/Register";
import PhoneNoLogin from "./components/Login/PhoneNoLogin";
import WelcomeLoginPage from "./pages/loginPages/WelcomeLoginPage";
import TrialCode from "./TrialCode";
import Dashboard from "./pages/Dashboard";
import MyModulePage from "./pages/MyModulePage";
import StudyStackPage from "./pages/StudyStackPage";
import AssignmentPage from "./pages/AssignmentPage";
import HistoryPage from "./pages/HistoryPage";
import MyProfilePage from "./pages/MyProfilePage";
import SettingsPage from "./pages/SettingsPage";
import LogoutPage from "./pages/LogoutPage";
import SupportPage from "./pages/SupportPage";
import StudyStackDetails from "./components/studyStackComp/StudyStackDetails";
import KioskApp from "./pages/KioskApp";
import ModuleExamPage from "./pages/ModuleExamPage";

//PrivateRoute component for authentication
const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("authToken");
  return isAuthenticated ? element : <Navigate to="/" />;
};

const App = () => {
  // useEffect(() => {
  //   // Function to handle the beforeunload event
  //   const handleBeforeUnload = () => {
  //     // Remove the token from local storage
  //     localStorage.removeItem("authToken");
  //   };

  //   // Attach the event listener when the component mounts
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Detach the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <>
      <Routes>
        {/* Public Routes */}
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<WelcomeLoginPage />} />
        {/* Private Routes */}

        <Route path="start-exam" element={<KioskApp />} />

        <Route
          path="/dashboard"
          element={
            <RootLayout>
              <PrivateRoute element={<Dashboard />} />
            </RootLayout>
          }
        />

        <Route
          path="/my-modules"
          element={
            <RootLayout>
              <PrivateRoute element={<MyModulePage />} />
            </RootLayout>
          }
        />
        <Route
          path="/study-stack"
          element={
            <RootLayout>
              <PrivateRoute element={<StudyStackPage />} />
            </RootLayout>
          }
        />
        <Route
          path="/study-stack-details"
          element={
            <RootLayout>
              <PrivateRoute element={<StudyStackDetails />} />
            </RootLayout>
          }
        />
        <Route
          path="/assignments"
          element={
            <RootLayout>
              <PrivateRoute element={<AssignmentPage />} />
            </RootLayout>
          }
        />
        <Route
          path="/history"
          element={
            <RootLayout>
              <PrivateRoute element={<HistoryPage />} />
            </RootLayout>
          }
        />
        <Route
          path="/my-profile"
          element={
            <RootLayout>
              <PrivateRoute element={<MyProfilePage />} />
            </RootLayout>
          }
        />
        <Route
          path="/settings"
          element={
            <RootLayout>
              <PrivateRoute element={<SettingsPage />} />
            </RootLayout>
          }
        />
        <Route
          path="/log-out"
          element={
            <RootLayout>
              <PrivateRoute element={<LogoutPage />} />
            </RootLayout>
          }
        />

        <Route
          path="/support"
          element={
            <RootLayout>
              <PrivateRoute element={<SupportPage />} />
            </RootLayout>
          }
        />

        <Route
          path="/start-module-exam"
          element={<PrivateRoute element={<ModuleExamPage />} />}
        />
      </Routes>
    </>
  );
};

export default App;
