import React from "react";

const InputField = ({
  inputFields,
  inputTypeHandler,
  userData,
  inputChangeHandler,
  errors,
}) => {
  return (
    <>
      {" "}
      {inputFields?.map((field, index) => {
        return (
          <div key={index} className="relative text_field_shadow rounded-lg w-full">
            <input
              type={inputTypeHandler(field.name)}
              id={`${field.name}-input-${index}`}
              name={field.name}
              value={userData[field.name]}
              onChange={inputChangeHandler}
              className={`diableNoBtn block rounded-lg px-2.5 pb-2.5 pt-6 w-full text-sm text-gray-900 bg-gray-50 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 bg-white peer 
            `}
              placeholder=" "
            />
            <label
              htmlFor={`${field.name}-input-${index}`}
              className={`absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto ${
                errors[field.name] ? "text-red-500" : ""
              }`}
            >
              {field.label}
            </label>
            {errors[field.name] && (
              <span className=" text-red-500 text-[8px] absolute left-1">
                {errors[field.name]}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};

export default InputField;
