import React from "react";

const Paragraph = ({
  description,
  goToRegistrationHandler,
  selectedProgram,
}) => {
  return (
    <div className="w-full md:pt-12 md:pb-10 md:px-16 col-span-3 text-center mt-3   bg-gradient-to-r from-purple-700 to-pink-500   rounded-tl-none rounded-bl-none rounded-tr-2xl rounded-br-2xl">
      <h1 className="text-white text-xl font-extrabold">
        {selectedProgram.toLowerCase() === "app"
          ? "Academic Pathway Planning"
          : selectedProgram}
      </h1>
      <p className="text-white text-justify text-[13px] font-normal mt-5">
        {description}
      </p>
      <div className="flex gap-6 m-auto justify-center mt-5">
        <button className="w-40 h-10 flex-shrink-0 rounded-lg border-2 border-white text-white text-center text-base font-normal">
          Know More
        </button>
        <button
          className="w-40 h-10 flex-shrink-0 rounded-lg border-2 border-white text-white text-center text-base font-normal"
          onClick={goToRegistrationHandler}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Paragraph;
