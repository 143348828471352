import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB36aWbZ2VotycLKgDLjV-Ve6xDcsQfhDA",
  authDomain: "vascareers-bb39a.firebaseapp.com",
  projectId: "vascareers-bb39a",
  storageBucket: "vascareers-bb39a.appspot.com",
  messagingSenderId: "191733174465",
  appId: "1:191733174465:web:c275ee0bb1835ca4ff0440",
  measurementId: "G-BE8BDXL085",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export default firebase;
