import React, { useState } from "react";
import main_Logo from "../../assets/images/main_logo.png";

import user_avatar from "../../assets/images/user_avatar.png";
import { useSelector } from "react-redux";
import BaseURL from "../../BaseURL/BaseURL";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const heading = useSelector((state) => state.heading);
  const navigate = useNavigate();
  const headText = heading.split("-").join(" ");
  const [logoutBtn, setLogoutBtn] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const logoutHandler = () => {
    setLogoutBtn(false);
    localStorage.removeItem("authToken");
    navigate("/");
    // axios
    //   .get(`${BaseURL.baseurl}/api/admin/logout`, {
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.message === "Admin logout successfully") {
    //       localStorage.removeItem("authToken");
    //       navigate("/")
    //     }
    //   })
    //   .catch((error) => {
    //     alert(error.message);
    //   });
  };

  return (
    <div className="h-[70px] py-3 px-5 flex justify-between ">
      {/* <img src={main_Logo} className="w-64 cursor-pointer h-11" /> */}
      <div className="text-blue-900 font-montserrat lgl:text-fs32 lg:text-fs22 mdl:text-fs18 md:text-fs22 font-bold capitalize  py-1.5">
        {headText}
      </div>
      {heading !== "dashboard" ? null : (
        <form className="w-1/2">
          <div className="relative w-full rounded-2xl  shadow-sm">
            <input
              type="search"
              id="default-search"
              className="block w-full mdm:p-2 mdm:pt-4 lg:p-3.5 text-sm text-gray-900 rounded-2xl focus:outline-none  focus:border-gray-500   "
              placeholder="Search here..."
            />
            <button className="absolute end-2.5 bottom-1.5 w-32 mdm:h-9 lg:h-10 flex-shrink-0 rounded-2xl bg-gradient-to-r from-purple-700 to-pink-500 text-white text-center font-montserrat text-fs16 font-bold">
              Search
            </button>
          </div>
        </form>
      )}

      <div className=" w-[150px] h-12 gap-5 flex justify-between items-center">
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clipPath="url(#clip0_72_30)">
              <path
                d="M27.3599 20.2799L24.9599 17.8666V11.9199C24.9919 9.71171 24.2238 7.56649 22.7974 5.88039C21.3711 4.19429 19.3829 3.08119 17.1999 2.74661C15.933 2.57976 14.6451 2.68503 13.4221 3.05537C12.1991 3.42572 11.0691 4.05264 10.1076 4.89429C9.14604 5.73594 8.37506 6.77298 7.84608 7.9362C7.31709 9.09942 7.04227 10.3621 7.03994 11.6399V17.8666L4.63993 20.2799C4.33848 20.5864 4.13405 20.975 4.0522 21.397C3.97036 21.819 4.01473 22.2558 4.17977 22.6528C4.34481 23.0497 4.6232 23.3892 4.98014 23.6288C5.33707 23.8685 5.75671 23.9975 6.1866 23.9999H10.6666V24.4533C10.7289 25.8069 11.3252 27.0806 12.3249 27.9953C13.3246 28.91 14.6461 29.3912 15.9999 29.3333C17.3537 29.3912 18.6753 28.91 19.675 27.9953C20.6747 27.0806 21.271 25.8069 21.3333 24.4533V23.9999H25.8133C26.2432 23.9975 26.6628 23.8685 27.0197 23.6288C27.3767 23.3892 27.6551 23.0497 27.8201 22.6528C27.9851 22.2558 28.0295 21.819 27.9477 21.397C27.8658 20.975 27.6614 20.5864 27.3599 20.2799ZM18.6666 24.4533C18.5927 25.0946 18.2741 25.6827 17.7774 26.095C17.2806 26.5073 16.6439 26.7121 15.9999 26.6666C15.356 26.7121 14.7193 26.5073 14.2225 26.095C13.7257 25.6827 13.4072 25.0946 13.3333 24.4533V23.9999H18.6666V24.4533Z"
                fill="#A098AE"
              />
            </g>
            <defs>
              <clipPath id="clip0_72_30">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            className="-ml-2"
          >
            <circle cx="4" cy="4" r="4" fill="url(#paint0_linear_72_35)" />
            <defs>
              <linearGradient
                id="paint0_linear_72_35"
                x1="-1.04009e-10"
                y1="3.56757"
                x2="8"
                y2="3.98799"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#672CD5" />
                <stop offset="1" stopColor="#E21185" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.25 4C14.4216 4 13.75 4.67157 13.75 5.5L13.5699 6.76051C13.4087 7.88912 12.0971 8.43241 11.185 7.74837L10.1664 6.98439C9.58059 6.3986 8.63084 6.3986 8.04505 6.98439L6.98439 8.04505C6.3986 8.63084 6.3986 9.58059 6.98439 10.1664L7.74836 11.185C8.43241 12.0971 7.88912 13.4087 6.76049 13.5699L5.5 13.75C4.67157 13.75 4 14.4216 4 15.25V16.75C4 17.5784 4.67157 18.25 5.5 18.25L6.76051 18.4301C7.88913 18.5913 8.43243 19.9029 7.74837 20.8149L6.98439 21.8336C6.3986 22.4195 6.3986 23.3692 6.98439 23.9549L8.04505 25.0156C8.63084 25.6015 9.58059 25.6015 10.1664 25.0156L11.185 24.2516C12.0971 23.5676 13.4087 24.1109 13.5699 25.2395L13.75 26.5C13.75 27.3284 14.4216 28 15.25 28H16.75C17.5784 28 18.25 27.3284 18.25 26.5L18.4301 25.2395C18.5913 24.1109 19.9029 23.5676 20.8149 24.2516L21.8336 25.0156C22.4195 25.6015 23.3692 25.6015 23.9549 25.0156L25.0156 23.9549C25.6013 23.3692 25.6013 22.4195 25.0156 21.8336L24.2516 20.8149C23.5676 19.9029 24.1109 18.5913 25.2395 18.4301L26.5 18.25C27.3284 18.25 28 17.5784 28 16.75V15.25C28 14.4216 27.3284 13.75 26.5 13.75L25.2395 13.5699C24.1109 13.4087 23.5676 12.0971 24.2516 11.185L25.0156 10.1664C25.6013 9.58059 25.6013 8.63084 25.0156 8.04505L23.9549 6.98439C23.3692 6.3986 22.4195 6.3986 21.8336 6.98439L20.8149 7.74837C19.9029 8.43241 18.5913 7.88912 18.4301 6.76051L18.25 5.5C18.25 4.67157 17.5784 4 16.75 4H15.25ZM16 19C17.6568 19 19 17.6568 19 16C19 14.3432 17.6568 13 16 13C14.3432 13 13 14.3432 13 16C13 17.6568 14.3432 19 16 19Z"
            fill="#A098AE"
          />
        </svg>
        <div className="relative">
          {" "}
          <img
            src={user_avatar}
            className="w-12 h-full rounded cursor-pointer"
            onMouseOver={() => setLogoutBtn(true)}
          />
          {logoutBtn && (
            <div className="absolute right-0 z-20 bg-white divide-y divide-gray-100 rounded  shadow w-20 hover:bg-gray-100 dark:hover:bg-gray-400  ">
              <ul
                className=" text-sm text-black "
                aria-labelledby="dropdownModuleSelect"
              >
                <li
                  className="block w-max capitalize px-4 py-2 dark:hover:text-white cursor-pointer"
                  onClick={logoutHandler}
                  onMouseLeave={() => setLogoutBtn(false)}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
