import React from "react";
import StudyStackList from "../components/studyStackComp/StudyStackList";

const StudyStackPage = () => {
  return (
    <div>
      <StudyStackList />
    </div>
  );
};

export default StudyStackPage;
