import React from "react";
import AssignmentQuestion from "../components/assignmentComp/AssignmentQuestion";

const AssignmentPage = () => {
  return (
    <div>
      <AssignmentQuestion />
    </div>
  );
};

export default AssignmentPage;
