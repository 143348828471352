import React from "react";
import pdfIcon from "../../assets/images/pdfIcon.svg";

const StudyStackDetails = () => {
  return (
    <>
      <section className="box-shadow p-5">
        <div className="flex justify-between">
          <h3 className="font-semibold text-sm">Guage Your Personality</h3>
          <div className="flex items-center gap-3">
            <h5 className="text-gradient-md text-sm ">Download as PDF</h5>
            <img src={pdfIcon} className="h-10" />
          </div>
        </div>
        <p className="text-[#777] text-xs -mt-4 w-4/5">
          Guage Your Personality tells you about the thing related to your
          personality Guage Your Personality tells you about the thing relate
        </p>

        <p className="text-[#292638] text-xs mt-4">
          Guage Your Personality tells you about the thing related to your
          personality Guage Your Personality tells you about the thing related
          to your personalityGuage Your Personality tells you about the thing
          related to your personalityGuage Your Personality tells you about the
          thing related to your personalityGuage Your Personality tells you
          about the thing related to your personalityGuage Your Personality
          tells you about the thing related to your personality
        </p>

        {[...Array(2)].map((item, i) => {
          return (
            <div className=" mt-10 flex items-start gap-5">
              <img
                src="https://i.ytimg.com/vi/RwHn6lrilIY/hqdefault.jpg"
                className="h-40 rounded "
              />
              <div>
                <h5 className="text-gradient-md text-sm mt-3">Watch Video</h5>
                <h3 className=" text-sm mt-3">Guage Your Personality</h3>
                <p className="text-[#292638] text-xs mt-3">
                  Guage Your Personality tells you about the thing related to
                  your personality Guage Your Personality tells you about the
                  thing related to your personalityGuage Your Personality tells
                  you about the thing related to your personalityGuage Your
                  Personality tells you about the thing related to your
                  personalityGuage Your Personality tells you about the thing
                  related to your personalityGuage Your Personality tells you
                  about the thing related to your personality
                </p>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default StudyStackDetails;
