import React, { useState, useEffect } from "react";

const KioskApp = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [detectScreen, setDetectScreen] = useState("");

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isFullScreen) {
        setDetectScreen(
          "You switched to another tab or application while in fullscreen mode!"
        );
        // alert(
        //   "You switched to another tab or application while in fullscreen mode!"
        // );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isFullScreen]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true);
        })
        .catch((err) => {
          console.error("Failed to enter full screen mode:", err);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false);
        })
        .catch((err) => {
          console.error("Failed to exit full screen mode:", err);
        });
    }
  };

  return (
    <div>
      {!isFullScreen && (
        <button onClick={toggleFullScreen}>Enter Full Screen</button>
      )}
      {isFullScreen && (
        <div
          style={{ backgroundColor: "black", width: "100vw", height: "100vh" }}
          className="flex justify-center items-center text-white"
        >
          <div>
            <h1 className="text-center font-semibold">Alert!!!</h1>
            <p className="text-center"> {detectScreen}</p>
            <button onClick={toggleFullScreen} className="border p-2">
              Exit Full Screen
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KioskApp;
