import { useEffect, useState } from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import main_logo from "../../assets/images/main_logo.png";
import logo_vas from "../../assets/images/logo_vas.png";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHeading, setOpenMenu } from "../../Store";

const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const screenWidth = window.innerWidth;
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const [activeNav, setActiveNav] = useState(1);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (screenWidth >= 900) {
      if (isTabletMid) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false);
    }
  }, [isTabletMid, screenWidth]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname, isTabletMid]);

  useEffect(() => {
    dispatch(setOpenMenu(open));
  }, [open]);
  useEffect(() => {
    dispatch(setHeading(pathname.slice(1)));
  }, []);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "13.5rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };

  const navigatorArr = [
    {
      id: 1,
      head: "Dashboard",
      path: ["/dashboard"],
      image:
        "M8.00004 5.33325C6.52728 5.33325 5.33337 6.52716 5.33337 7.99992V11.9999C5.33337 13.4727 6.52728 14.6666 8.00004 14.6666H12C13.4728 14.6666 14.6667 13.4727 14.6667 11.9999V7.99992C14.6667 6.52716 13.4728 5.33325 12 5.33325H8.00004ZM20 5.33325C18.5272 5.33325 17.3334 6.52716 17.3334 7.99992V11.9999C17.3334 13.4727 18.5272 14.6666 20 14.6666H24C25.4728 14.6666 26.6667 13.4727 26.6667 11.9999V7.99992C26.6667 6.52716 25.4728 5.33325 24 5.33325H20ZM5.33337 19.9999C5.33337 18.5271 6.52728 17.3333 8.00004 17.3333H12C13.4728 17.3333 14.6667 18.5271 14.6667 19.9999V23.9999C14.6667 25.4727 13.4728 26.6666 12 26.6666H8.00004C6.52728 26.6666 5.33337 25.4727 5.33337 23.9999V19.9999ZM20 17.3333C18.5272 17.3333 17.3334 18.5271 17.3334 19.9999V23.9999C17.3334 25.4727 18.5272 26.6666 20 26.6666H24C25.4728 26.6666 26.6667 25.4727 26.6667 23.9999V19.9999C26.6667 18.5271 25.4728 17.3333 24 17.3333H20Z",
    },
    {
      id: 2,
      head: "My Modules",
      path: ["/my-modules"],
      image:
        "M28.2667 24.1334C27.3334 24.0001 26.4 23.8668 25.3334 23.8668C22 23.8668 18.8 24.8001 16 26.6668C13.2 24.9334 10 23.8668 6.66669 23.8668C5.73335 23.8668 4.66669 24.0001 3.73335 24.1334C3.06669 24.2668 2.53335 24.9334 2.66669 25.7334C2.80002 26.5334 3.46669 26.9334 4.26669 26.8001C5.06669 26.6668 5.86669 26.5334 6.66669 26.5334C9.73335 26.5334 12.6667 27.4668 15.2 29.3334C15.6 29.7334 16.2667 29.7334 16.8 29.3334C20 27.0668 24 26.1334 27.7334 26.8001C28.4 26.9334 29.2 26.4001 29.3334 25.7334C29.4667 24.9334 28.9334 24.2668 28.2667 24.1334ZM28.2667 2.80011C27.3334 2.66678 26.4 2.53345 25.3334 2.53345C22 2.53345 18.8 3.46678 16 5.33345C13.2 3.46678 10 2.53345 6.66669 2.53345C5.73335 2.53345 4.66669 2.66678 3.73335 2.80011C3.20002 2.80011 2.66669 3.46678 2.66669 4.00011V20.0001C2.66669 20.8001 3.20002 21.3334 4.00002 21.3334C4.13335 21.3334 4.13335 21.3334 4.26669 21.3334C5.06669 21.2001 5.86669 21.0668 6.66669 21.0668C9.73335 21.0668 12.6667 22.0001 15.2 23.8668C15.6 24.2668 16.2667 24.2668 16.8 23.8668C20 21.6001 24 20.6668 27.7334 21.3334C28.4 21.4668 29.2 20.9334 29.3334 20.2668C29.3334 20.1334 29.3334 20.1334 29.3334 20.0001V4.00011C29.3334 3.46678 28.8 2.80011 28.2667 2.80011Z",
    },
    {
      id: 3,
      head: "Study Stack",
      path: ["/study-stack", "/study-stack-details"],
      image:
        "M11.3334 15.3334C14.2667 15.3334 16.6667 12.9334 16.6667 10.0001C16.6667 7.06675 14.2667 4.66675 11.3334 4.66675C8.40004 4.66675 6.00004 7.06675 6.00004 10.0001C6.00004 12.9334 8.40004 15.3334 11.3334 15.3334ZM17.3334 14.1334C19.6 16.0001 22.9334 15.6001 24.8 13.3334C26.6667 11.0667 26.2667 7.73341 24 5.86675C23.0667 5.06675 21.8667 4.66675 20.6667 4.66675C19.4667 4.66675 18.2667 5.06675 17.3334 5.86675C19.6 7.73341 20 10.9334 18.1334 13.3334C18 13.6001 17.6 13.8667 17.3334 14.1334ZM30.5334 25.8667C29.7334 20.9334 25.6 17.3334 20.6667 17.3334C19.4667 17.3334 18.4 17.4667 17.3334 17.8667C20.8 19.0667 23.3334 22.1334 23.8667 25.7334C24 26.4001 23.4667 27.2001 22.6667 27.2001H22.5334H29.2C30 27.2001 30.5334 26.6667 30.5334 25.8667C30.5334 26.0001 30.5334 25.8667 30.5334 25.8667ZM11.3334 17.3334C6.40004 17.3334 2.13337 20.9334 1.46671 25.8667C1.33337 26.5334 1.86671 27.3334 2.66671 27.3334H2.80004H19.8667C20.6667 27.3334 21.2 26.8001 21.2 26.0001V25.8667C20.5334 20.9334 16.2667 17.3334 11.3334 17.3334Z",
    },
    {
      id: 4,
      head: "Assignment",
      path: ["/assignments"],
      image:
        "M16 16.0001C19.7333 16.0001 22.6666 13.0667 22.6666 9.33342C22.6666 5.60008 19.7333 2.66675 16 2.66675C12.2666 2.66675 9.33331 5.60008 9.33331 9.33342C9.33331 13.0667 12.2666 16.0001 16 16.0001ZM28.4 27.7334C26.6666 20.9334 19.7333 16.6667 12.9333 18.4001C8.39997 19.6001 4.79997 23.0667 3.59997 27.7334C3.46664 28.4001 3.86664 29.2001 4.66664 29.3334C4.79997 29.3334 4.93331 29.3334 4.93331 29.3334H27.0666C27.8666 29.3334 28.4 28.8001 28.4 28.0001C28.4 27.8667 28.4 27.7334 28.4 27.7334Z",
    },
    {
      id: 5,
      head: "History",
      path: ["/history"],
      image:
        "M26 4.46997H24.51V3.5C24.51 2.67999 23.84 2 23.01 2C22.18 2 21.51 2.67999 21.51 3.5V4.46997H17.5V3.5C17.5 2.67999 16.83 2 16 2C15.17 2 14.5 2.67999 14.5 3.5V4.46997H10.49V3.5C10.49 2.67999 9.82001 2 8.98999 2C8.15997 2 7.48999 2.67999 7.48999 3.5V4.46997H6C2.96997 4.46997 0.5 6.92999 0.5 9.96997V24.5C0.5 27.53 2.96997 30 6 30H26C29.03 30 31.5 27.53 31.5 24.5V9.96997C31.5 6.92999 29.03 4.46997 26 4.46997ZM28.5 11.62H3.5V9.96997C3.5 8.58997 4.62 7.46997 6 7.46997H7.48999V8.38C7.48999 9.20996 8.15997 9.88 8.98999 9.88C9.82001 9.88 10.49 9.20996 10.49 8.38V7.46997H14.5V8.38C14.5 9.20996 15.17 9.88 16 9.88C16.83 9.88 17.5 9.20996 17.5 8.38V7.46997H21.51V8.38C21.51 9.20996 22.18 9.88 23.01 9.88C23.84 9.88 24.51 9.20996 24.51 8.38V7.46997H26C27.38 7.46997 28.5 8.58997 28.5 9.96997V11.62Z",
    },
  ];

  const settingNavigatorArr = [
    {
      id: 11,
      head: "My Profile",
      path: ["/my-profile"],
      image:
        "M8.00004 5.33325C6.52728 5.33325 5.33337 6.52716 5.33337 7.99992V11.9999C5.33337 13.4727 6.52728 14.6666 8.00004 14.6666H12C13.4728 14.6666 14.6667 13.4727 14.6667 11.9999V7.99992C14.6667 6.52716 13.4728 5.33325 12 5.33325H8.00004ZM20 5.33325C18.5272 5.33325 17.3334 6.52716 17.3334 7.99992V11.9999C17.3334 13.4727 18.5272 14.6666 20 14.6666H24C25.4728 14.6666 26.6667 13.4727 26.6667 11.9999V7.99992C26.6667 6.52716 25.4728 5.33325 24 5.33325H20ZM5.33337 19.9999C5.33337 18.5271 6.52728 17.3333 8.00004 17.3333H12C13.4728 17.3333 14.6667 18.5271 14.6667 19.9999V23.9999C14.6667 25.4727 13.4728 26.6666 12 26.6666H8.00004C6.52728 26.6666 5.33337 25.4727 5.33337 23.9999V19.9999ZM20 17.3333C18.5272 17.3333 17.3334 18.5271 17.3334 19.9999V23.9999C17.3334 25.4727 18.5272 26.6666 20 26.6666H24C25.4728 26.6666 26.6667 25.4727 26.6667 23.9999V19.9999C26.6667 18.5271 25.4728 17.3333 24 17.3333H20Z",
    },
    {
      id: 22,
      head: "Settings",
      path: ["/settings"],
      image:
        "M28.2667 24.1334C27.3334 24.0001 26.4 23.8668 25.3334 23.8668C22 23.8668 18.8 24.8001 16 26.6668C13.2 24.9334 10 23.8668 6.66669 23.8668C5.73335 23.8668 4.66669 24.0001 3.73335 24.1334C3.06669 24.2668 2.53335 24.9334 2.66669 25.7334C2.80002 26.5334 3.46669 26.9334 4.26669 26.8001C5.06669 26.6668 5.86669 26.5334 6.66669 26.5334C9.73335 26.5334 12.6667 27.4668 15.2 29.3334C15.6 29.7334 16.2667 29.7334 16.8 29.3334C20 27.0668 24 26.1334 27.7334 26.8001C28.4 26.9334 29.2 26.4001 29.3334 25.7334C29.4667 24.9334 28.9334 24.2668 28.2667 24.1334ZM28.2667 2.80011C27.3334 2.66678 26.4 2.53345 25.3334 2.53345C22 2.53345 18.8 3.46678 16 5.33345C13.2 3.46678 10 2.53345 6.66669 2.53345C5.73335 2.53345 4.66669 2.66678 3.73335 2.80011C3.20002 2.80011 2.66669 3.46678 2.66669 4.00011V20.0001C2.66669 20.8001 3.20002 21.3334 4.00002 21.3334C4.13335 21.3334 4.13335 21.3334 4.26669 21.3334C5.06669 21.2001 5.86669 21.0668 6.66669 21.0668C9.73335 21.0668 12.6667 22.0001 15.2 23.8668C15.6 24.2668 16.2667 24.2668 16.8 23.8668C20 21.6001 24 20.6668 27.7334 21.3334C28.4 21.4668 29.2 20.9334 29.3334 20.2668C29.3334 20.1334 29.3334 20.1334 29.3334 20.0001V4.00011C29.3334 3.46678 28.8 2.80011 28.2667 2.80011Z",
    },
    {
      id: 33,
      head: "Log out",
      path: ["/log-out"],
      image:
        "M11.3334 15.3334C14.2667 15.3334 16.6667 12.9334 16.6667 10.0001C16.6667 7.06675 14.2667 4.66675 11.3334 4.66675C8.40004 4.66675 6.00004 7.06675 6.00004 10.0001C6.00004 12.9334 8.40004 15.3334 11.3334 15.3334ZM17.3334 14.1334C19.6 16.0001 22.9334 15.6001 24.8 13.3334C26.6667 11.0667 26.2667 7.73341 24 5.86675C23.0667 5.06675 21.8667 4.66675 20.6667 4.66675C19.4667 4.66675 18.2667 5.06675 17.3334 5.86675C19.6 7.73341 20 10.9334 18.1334 13.3334C18 13.6001 17.6 13.8667 17.3334 14.1334ZM30.5334 25.8667C29.7334 20.9334 25.6 17.3334 20.6667 17.3334C19.4667 17.3334 18.4 17.4667 17.3334 17.8667C20.8 19.0667 23.3334 22.1334 23.8667 25.7334C24 26.4001 23.4667 27.2001 22.6667 27.2001H22.5334H29.2C30 27.2001 30.5334 26.6667 30.5334 25.8667C30.5334 26.0001 30.5334 25.8667 30.5334 25.8667ZM11.3334 17.3334C6.40004 17.3334 2.13337 20.9334 1.46671 25.8667C1.33337 26.5334 1.86671 27.3334 2.66671 27.3334H2.80004H19.8667C20.6667 27.3334 21.2 26.8001 21.2 26.0001V25.8667C20.5334 20.9334 16.2667 17.3334 11.3334 17.3334Z",
    },
  ];

  const supportNavigatorArr = [
    {
      id: 101,
      head: "Support",
      path: ["/support"],
      image:
        "M8.00004 5.33325C6.52728 5.33325 5.33337 6.52716 5.33337 7.99992V11.9999C5.33337 13.4727 6.52728 14.6666 8.00004 14.6666H12C13.4728 14.6666 14.6667 13.4727 14.6667 11.9999V7.99992C14.6667 6.52716 13.4728 5.33325 12 5.33325H8.00004ZM20 5.33325C18.5272 5.33325 17.3334 6.52716 17.3334 7.99992V11.9999C17.3334 13.4727 18.5272 14.6666 20 14.6666H24C25.4728 14.6666 26.6667 13.4727 26.6667 11.9999V7.99992C26.6667 6.52716 25.4728 5.33325 24 5.33325H20ZM5.33337 19.9999C5.33337 18.5271 6.52728 17.3333 8.00004 17.3333H12C13.4728 17.3333 14.6667 18.5271 14.6667 19.9999V23.9999C14.6667 25.4727 13.4728 26.6666 12 26.6666H8.00004C6.52728 26.6666 5.33337 25.4727 5.33337 23.9999V19.9999ZM20 17.3333C18.5272 17.3333 17.3334 18.5271 17.3334 19.9999V23.9999C17.3334 25.4727 18.5272 26.6666 20 26.6666H24C25.4728 26.6666 26.6667 25.4727 26.6667 23.9999V19.9999C26.6667 18.5271 25.4728 17.3333 24 17.3333H20Z",
    },
  ];

  const headingSelectHandler = (getHeading) => {
    dispatch(setHeading(getHeading.slice(1)));
  };

  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 max-h-hvh z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></div>

      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        // initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" text-gray shadow-xl z-[999]  w-[16rem]  overflow-hidden md:relative fixed h-[100vh] "
        style={{
          background: "rgba(226, 17, 133, 0.09)",
        }}
      >
        {open ? <div className=" py-3 border-slate-300  h-h70 " /> : null}

        <div className="flex flex-col h-h100p ">
          <div className="h-h51 p-2">
            {open ? (
              <img src={main_logo} className="w-w100p h-h100p" />
            ) : (
              <img src={logo_vas} className="w-w100p h-h100p" />
            )}
          </div>
          <ul
            className={`${open ? "px-4" : "px-0 ml-1 mt-16"} 
          overflow-auto hide_scrollbar whitespace-pre  text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[68%] lg:h-[75%] `}
          >
            {navigatorArr.map((val, i) => {
              return (
                <li key={i}>
                  <NavLink
                    to={`${val.path[0]}`}
                    onClick={() => headingSelectHandler(val.path[0])}
                    className={`relative flex items-center  rounded-lg transition-colors 
                    ${
                      open
                        ? "pl-1.5 py-2 pr-2"
                        : "pl-3.5 py-2 pr-0 border border-500-red"
                    }
                    ${
                      val.path.includes(pathname)
                        ? "bg-gradient-to-r from-purple-700 to-pink-500 text-white "
                        : "bg-[rgba(226, 17, 133, 0.09)] text-[#A098AE] hover:bg-opacity-10"
                    } 
                    font-montserrat text-fs16  hover:font-bold  hover:shadow-sm rounded-br10 hover:rounded-br10`}
                  >
                    <span className="absolute inset-0 rounded-lg bg-white opacity-0 transition-opacity hover:opacity-20"></span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="20"
                        fill="none"
                      >
                        <path
                          d={val.image}
                          fill={`url(#paint0_linear_${val.path})`}
                        />
                        <defs>
                          <linearGradient
                            id={`paint0_linear_${val.path}`}
                            x1="2.64612"
                            y1="14.6186"
                            x2="29.356"
                            y2="16.0019"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              id={`stop1_${val.path}`}
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                            <stop
                              id={`stop2_${val.path}`}
                              offset="1"
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    {open ? (
                      <span className="ml-3  text-fs14 ">{val.head}</span>
                    ) : null}
                  </NavLink>
                </li>
              );
            })}
          </ul>

          <ul
            className={`${open ? "px-4" : "px-0 ml-1 "} mt-20
          overflow-auto hide_scrollbar whitespace-pre  text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[68%] lg:h-[75%] `}
          >
            {settingNavigatorArr.map((val, i) => {
              return (
                <li key={i}>
                  <NavLink
                    to={`${val.path[0]}`}
                    onClick={() => headingSelectHandler(val.path[0])}
                    className={`relative flex items-center  rounded-lg transition-colors 
                    ${
                      open
                        ? "pl-1.5 py-2 pr-2"
                        : "pl-3.5 py-2 pr-0 border border-500-red"
                    }
                    ${
                      val.path.includes(pathname)
                        ? "bg-gradient-to-r from-purple-700 to-pink-500 text-white "
                        : "bg-[rgba(226, 17, 133, 0.09)] text-[#A098AE] hover:bg-opacity-10"
                    } 
                    font-montserrat text-fs16  hover:font-bold  hover:shadow-sm rounded-br10 hover:rounded-br10`}
                  >
                    <span className="absolute inset-0 rounded-lg bg-white opacity-0 transition-opacity hover:opacity-20"></span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="20"
                        fill="none"
                      >
                        <path
                          d={val.image}
                          fill={`url(#paint0_linear_${val.path})`}
                        />
                        <defs>
                          <linearGradient
                            id={`paint0_linear_${val.path}`}
                            x1="2.64612"
                            y1="14.6186"
                            x2="29.356"
                            y2="16.0019"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              id={`stop1_${val.path}`}
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                            <stop
                              id={`stop2_${val.path}`}
                              offset="1"
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    {open ? (
                      <span className="ml-3  text-fs14 ">{val.head}</span>
                    ) : null}
                  </NavLink>
                </li>
              );
            })}
          </ul>

          <ul
            className={`${open ? "px-4" : "px-0 ml-1 "} mt-4
          overflow-auto hide_scrollbar whitespace-pre  text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100   md:h-[68%] lg:h-[75%] `}
          >
            {supportNavigatorArr.map((val, i) => {
              return (
                <li key={i}>
                  <NavLink
                    to={`${val.path[0]}`}
                    onClick={() => headingSelectHandler(val.path[0])}
                    className={`relative flex items-center  rounded-lg transition-colors 
                    ${
                      open
                        ? "pl-1.5 py-2 pr-2"
                        : "pl-3.5 py-2 pr-0 border border-500-red"
                    }
                    ${
                      val.path.includes(pathname)
                        ? "bg-gradient-to-r from-purple-700 to-pink-500 text-white "
                        : "bg-[rgba(226, 17, 133, 0.09)] text-[#A098AE] hover:bg-opacity-10"
                    } 
                    font-montserrat text-fs16  hover:font-bold  hover:shadow-sm rounded-br10 hover:rounded-br10`}
                  >
                    <span className="absolute inset-0 rounded-lg bg-white opacity-0 transition-opacity hover:opacity-20"></span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="20"
                        fill="none"
                      >
                        <path
                          d={val.image}
                          fill={`url(#paint0_linear_${val.path})`}
                        />
                        <defs>
                          <linearGradient
                            id={`paint0_linear_${val.path}`}
                            x1="2.64612"
                            y1="14.6186"
                            x2="29.356"
                            y2="16.0019"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop
                              id={`stop1_${val.path}`}
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                            <stop
                              id={`stop2_${val.path}`}
                              offset="1"
                              stopColor={
                                val.path.includes(pathname)
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    {open ? (
                      <span className="ml-3  text-fs14 ">{val.head}</span>
                    ) : null}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <motion.div
          onClick={() => {
            setOpen(!open);
          }}
          animate={
            open
              ? {
                  x: 0,
                  y: 0,
                  rotate: 0,
                }
              : {
                  x: -10,
                  rotate: 180,
                }
          }
          transition={{ duration: 0 }}
          className="absolute w-fit h-fit md:block z-50 hidden right-2 bottom-3 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            color="#672CD5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
        </motion.div>
      </motion.div>
      <div className="m-3 md:hidden font-bold " onClick={() => setOpen(true)}>
        Menu
      </div>
    </div>
  );
};

export default Sidebar;
