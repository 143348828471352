import React from "react";
import Login from "../../components/Login/Login";
import Welcome from "../../components/Login/Welcome";
import Header from "../../components/Header/Header";
import main_Logo from "../../assets/images/main_logo.png";

const WelcomeLoginPage = () => {
  return (
    <>
      <div className=" px-6 py-2 fixed w-full top-0 left-0 bg-white z-50 ">
        <img src={main_Logo} className="w-64 cursor-pointer h-11" />
      </div>
      <Welcome />
    </>
  );
};

export default WelcomeLoginPage;
