import React, { useState, useEffect } from "react";
import RangeSlider from "../commonComponents/RangeSlider";
import checkboxIcon from "../../assets/images/checkBoxIcon.svg";
import checkTickIcon from "../../assets/images/checkboxTickIcon.svg";
import thumbDownIcon from "../../assets/images/thumbDownIcon.svg";
import thumbUpIcon from "../../assets/images/thumbUpIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import BaseURL from "../../BaseURL/BaseURL";
import axios from "axios";

const AssignmentQuestion = () => {
  const navigate = useNavigate();

  const [checkedBox, setCheckBox] = useState("Yes");
  const singleCheckBoxArr = ["Yes", "No"];

  //   useEffect(() => {
  //     if (!authToken) {
  //       console.error("No auth token available.");
  //       return;
  //     }
  //     axios
  //       .get(
  //         `${BaseURL.baseurl}/api/module/getQuestionList?moduleID=${moduleId}&attempt=${attempType}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response.data.moduleQuestions);
  //       })
  //       .catch((err) => alert(err));
  //   }, []);

  return (
    <div>
      {" "}
      <section className="px-5 pb-28">
        <h4 className="font-semibold mt-2">
          1.Tell us about yourself(Text Question)
        </h4>
        <textarea
          type="text"
          rows={4}
          placeholder="Answer"
          className=" w-4/5 mt-4 p-4 shadow rounded-md  resize-none"
        />
        <h4 className="font-semibold mt-14">
          2. Please choose your favorite ice-cream flavor(Image Question)
        </h4>
        <div className="grid grid-cols-4 mt-4 gap-14 ">
          {["Strawberry", "Butterscotch", "Vanilla", "Chocolate"].map(
            (item, i) => {
              return (
                <div key={i} className="col-span-1">
                  <img
                    src="https://img.freepik.com/premium-photo/chocolate-vanilla-strawberry-ice-cream-isolated-white_807701-3441.jpg"
                    className="h-h200 w-w100p"
                  />
                  <p className="text-center mt-4">{item}</p>
                </div>
              );
            }
          )}
        </div>
        <h4 className="font-semibold mt-14">
          3. How Satisfied are you with overall experience?(Scale (Slider)
        </h4>
        <RangeSlider />
        <h4 className="font-semibold mt-14">
          4. Are you Accepting our terms and conditions?(Single Check-box)
        </h4>
        {singleCheckBoxArr.map((val, i) => {
          return (
            <label key={i} className="flex  items-center gap-2 mt-4">
              <svg
                width="32"
                height="25"
                viewBox="0 0 32 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="31"
                  height="24"
                  rx="4.5"
                  stroke="url(#paint0_linear_1787_1295)"
                />
                {checkedBox === val && (
                  <image
                    href={checkTickIcon}
                    x="50%"
                    y="50%"
                    width="16"
                    height="16"
                    transform="translate(-8, -8)"
                  />
                )}

                <defs>
                  <linearGradient
                    id="paint0_linear_1787_1295"
                    x1="-4.16037e-10"
                    y1="11.1486"
                    x2="31.9438"
                    y2="13.2974"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#672CD5" />
                    <stop offset="1" stopColor="#E21185" />
                  </linearGradient>
                </defs>
              </svg>
              <span>{val}</span>
            </label>
          );
        })}
        <h4 className="font-semibold mt-14">
          5. How did you hear about us?(Multiple Check-box)
        </h4>
        {["Opt1", "Otp2", "Opt3", "Opt4"].map((val, i) => {
          return (
            <label key={i} className="flex  items-center gap-2 mt-4">
              <svg
                width="32"
                height="25"
                viewBox="0 0 32 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="31"
                  height="24"
                  rx="4.5"
                  stroke="url(#paint0_linear_1787_1295)"
                />
                <image
                  href={checkTickIcon}
                  x="50%"
                  y="50%"
                  width="16"
                  height="16"
                  transform="translate(-8, -8)"
                />

                <defs>
                  <linearGradient
                    id="paint0_linear_1787_1295"
                    x1="-4.16037e-10"
                    y1="11.1486"
                    x2="31.9438"
                    y2="13.2974"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#672CD5" />
                    <stop offset="1" stopColor="#E21185" />
                  </linearGradient>
                </defs>
              </svg>
              <span>{val}</span>
            </label>
          );
        })}
        <h4 className="font-semibold mt-14">
          6. Please rank the following in terms of your senses(Ranking Rating)
        </h4>
        {["Hear", "Smell", "Taste", "Sight"].map((val, i) => {
          return (
            <div key={i} className="flex gap-10 mt-8">
              <span className="w-w50 ">{val}</span>

              <div className="flex gap-3">
                {[...Array(4)].map((_, i) => {
                  return (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                    >
                      <defs>
                        <linearGradient
                          id="background_gradient"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="100%"
                        >
                          <stop offset="0%" stopColor="#FF8A00" />
                          <stop offset="100%" stopColor="#E52E71" />
                        </linearGradient>
                      </defs>
                      {/* <rect width="100%" height="100%" fill="url(#background_gradient)"/> */}

                      <path
                        d="M21.5 10C21.5 15.2029 16.8449 19.5 11 19.5C5.1551 19.5 0.5 15.2029 0.5 10C0.5 4.79707 5.1551 0.5 11 0.5C16.8449 0.5 21.5 4.79707 21.5 10Z"
                        stroke="url(#paint0_linear_1787_1338)"
                      />

                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fontSize="10"
                        fill="black"
                      >
                        5
                      </text>

                      <defs>
                        <linearGradient
                          id="paint0_linear_1787_1338"
                          x1="-2.86025e-10"
                          y1="8.91892"
                          x2="21.9873"
                          y2="10.19"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#672CD5" />
                          <stop offset="1" stopColor="#E21185" />
                        </linearGradient>
                      </defs>
                    </svg>
                  );
                })}
              </div>
            </div>
          );
        })}
        <h4 className="font-semibold mt-14">
          7. Are you motivated to go for a walk every morning?(Visual Analog
          Questions)
        </h4>

        <div className="flex gap-10 mt-6">
          <img src={thumbUpIcon} className="w-w50" />
          <img src={thumbDownIcon} className="w-w50" />
        </div>
        <h4 className="font-semibold mt-14">
          what are your view on our services, explain briefly?(Comment Box)
        </h4>
        <textarea
          type="text"
          rows={4}
          placeholder="Answer"
          className=" w-4/5 mt-4 p-4 shadow rounded-md  resize-none"
        />
        <h4 className="font-semibold mt-14">Heading Question is left</h4>
        <textarea
          type="text"
          rows={1}
          placeholder="Answer"
          className=" w-4/5 mt-4 p-4 shadow rounded-md  resize-none"
        />
      </section>
    </div>
  );
};

export default AssignmentQuestion;
