import React, { useEffect, useState } from "react";
import timerIcon from "../../assets/images/timerIcon.svg";
import RangeSlider from "../commonComponents/RangeSlider";
import checkTickIcon from "../../assets/images/checkboxTickIcon.svg";
import thumbDownIcon from "../../assets/images/thumbDownIcon.svg";
import thumbUpIcon from "../../assets/images/thumbUpIcon.svg";
import thumbUpIconSelect from "../../assets/images/thumbUpIconSelect.svg";
import thumbDownIconSelect from "../../assets/images/thumbDownIconSelected.svg";
import { useDispatch, useSelector } from "react-redux";
import { setQuestionNumber } from "../../Store";

const ExamQuestionComp = ({ countDownTimer, allQuestionsList }) => {
  const dispatch = useDispatch();
  const questionNumberG = useSelector((item) => item.questionNumber);
  const [checkedBox, setCheckBox] = useState("");
  const [multipleCheckBox, setMultipleCheckBox] = useState([]);
  const [questionDetail, setQuestionDetail] = useState({});
  const [questionType, setQuestionType] = useState("");

  // const []

  console.log("questionDetail", allQuestionsList);

  useEffect(() => {
    setQuestionDetail(allQuestionsList[questionNumberG]);
    setQuestionType(allQuestionsList[questionNumberG].type);
  }, [questionNumberG]);

  const nextQuestionHandler = (getQNumber) => {
    if (allQuestionsList.length !== getQNumber) {
      dispatch(setQuestionNumber(getQNumber));
    }
  };

  const multipleCheckBoxHandler = (getIds) => {
    if (!multipleCheckBox.includes(getIds)) {
      setMultipleCheckBox([...multipleCheckBox, getIds]);
    } else {
      const removeId = multipleCheckBox.filter((val) => val !== getIds);
      setMultipleCheckBox(removeId);
    }
  };

  const inputTextHandler = (e) => {
    console.log(e.target.value);
  };

  return (
    <section className=" w-4/5">
      <div className="flex justify-between px-5 pt-8 pb-1">
        <h2 className="font-bold">Module Title</h2>

        <div className="text-center">
          <img src={timerIcon} className="h-12 w-12" />
          <p>{countDownTimer}</p>
        </div>
      </div>
      <article className="h-[88vh] pb-16 overflow-x-scroll">
        <h2 className="font-semibold px-5">
          Question No. {questionNumberG + 1}
        </h2>
        <p className="text-[#777] px-5 py-2 w-4/5">
          {questionDetail.instruction?.charAt(0).toUpperCase() +
            questionDetail.instruction?.slice(1)}
          the following question has been designed to assess your personality
          traits and characteristics. This question framed by professionals
          after careful and extensive research, will give us an insight into
          your nature and temperament. Since a person’s personality not choice
          or profession, but also plays a role.
        </p>
        <h2 className="font-semibold px-5 mt-5">
          {questionDetail.question?.charAt(0).toUpperCase() +
            questionDetail.question?.slice(1)}
        </h2>

        <div className="px-6 mt-5">
          {" "}
          {questionType === "textTypeQuestion" && (
            <textarea
              type="text"
              rows={5}
              placeholder="Answer"
              className="w-4/5 p-4 shadow rounded-xl  focus:outline-none resize-none"
            />
          )}
          {questionType === "headingTypeQuestion" && (
            <textarea
              type="text"
              rows={5}
              placeholder="Answer"
              className="w-4/5 p-4 shadow rounded-xl  focus:outline-none resize-none"
            />
          )}
          {questionType === "commentTypeQuestion" && (
            <textarea
              type="text"
              rows={5}
              placeholder="Your comment"
              className="w-4/5 p-4 shadow rounded-xl  focus:outline-none resize-none"
            />
          )}
          {questionType === "imageTypeQuestion" && (
            <div className="grid grid-cols-4  gap-14 w-11/12  ">
              {questionDetail.optionImages.map((item, i) => {
                return (
                  <div key={i} className="col-span-1 cursor-pointer">
                    <img
                      src={item.optionImage.Location}
                      className="h-[200px] w-full"
                    />
                    <p className="text-center mt-4">{item.optionText}</p>
                  </div>
                );
              })}
            </div>
          )}
          {questionType === "scaleTypeQuestion" && (
            <RangeSlider
              startRange={questionDetail.startRange}
              endRange={questionDetail.endRange}
              steps={questionDetail.steps}
            />
          )}
          {questionType === "singleCheckBoxTypeQuestion" && (
            <>
              {" "}
              {questionDetail.options.map((val, i) => {
                return (
                  <label
                    key={val.optionID}
                    className="flex  items-center gap-2 mt-4"
                    onClick={() => setCheckBox(val.optionID)}
                  >
                    <svg
                      width="32"
                      height="25"
                      viewBox="0 0 32 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="31"
                        height="24"
                        rx="4.5"
                        stroke="url(#paint0_linear_1787_1295)"
                      />
                      {val.optionID === checkedBox ? (
                        <image
                          href={checkTickIcon}
                          x="50%"
                          y="50%"
                          width="16"
                          height="16"
                          transform="translate(-8, -8)"
                        />
                      ) : null}

                      <defs>
                        <linearGradient
                          id="paint0_linear_1787_1295"
                          x1="-4.16037e-10"
                          y1="11.1486"
                          x2="31.9438"
                          y2="13.2974"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#672CD5" />
                          <stop offset="1" stopColor="#E21185" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{val.option}</span>
                  </label>
                );
              })}
            </>
          )}
          {questionType === "multipleCheckBoxTypeQuestion" && (
            <>
              {" "}
              {questionDetail.options.map((val, i) => {
                return (
                  <label
                    key={i}
                    className="flex  items-center gap-2 mt-4"
                    onClick={() => multipleCheckBoxHandler(val.optionID)}
                  >
                    <svg
                      width="32"
                      height="25"
                      viewBox="0 0 32 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="31"
                        height="24"
                        rx="4.5"
                        stroke="url(#paint0_linear_1787_1295)"
                      />
                      {multipleCheckBox.includes(val.optionID) && (
                        <image
                          href={checkTickIcon}
                          x="50%"
                          y="50%"
                          width="16"
                          height="16"
                          transform="translate(-8, -8)"
                        />
                      )}

                      <defs>
                        <linearGradient
                          id="paint0_linear_1787_1295"
                          x1="-4.16037e-10"
                          y1="11.1486"
                          x2="31.9438"
                          y2="13.2974"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#672CD5" />
                          <stop offset="1" stopColor="#E21185" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{val.option}</span>
                  </label>
                );
              })}
            </>
          )}
          {questionType === "ratingTypeQuestion" && (
            <>
              {questionDetail.options.map((val, i) => {
                return (
                  <label
                    key={i}
                    className="flex  items-center gap-2 mt-4"
                    onClick={() => setCheckBox(val.optionID)}
                  >
                    <svg
                      width="32"
                      height="25"
                      viewBox="0 0 32 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="31"
                        height="24"
                        rx="4.5"
                        stroke="url(#paint0_linear_1787_1295)"
                      />
                      {val.optionID === checkedBox ? (
                        <image
                          href={checkTickIcon}
                          x="50%"
                          y="50%"
                          width="16"
                          height="16"
                          transform="translate(-8, -8)"
                        />
                      ) : null}

                      <defs>
                        <linearGradient
                          id="paint0_linear_1787_1295"
                          x1="-4.16037e-10"
                          y1="11.1486"
                          x2="31.9438"
                          y2="13.2974"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#672CD5" />
                          <stop offset="1" stopColor="#E21185" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{val.option}</span>
                  </label>
                );
              })}
              {["Hear", "Smell", "Taste", "Sight"].map((val, i) => {
                return (
                  <div key={i} className="flex gap-10 mt-8">
                    <span className="w-w50 ">{val}</span>

                    <div className="flex gap-3">
                      {[...Array(5)].map((_, i) => {
                        return (
                          <svg
                            key={i}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                          >
                            <defs>
                              <linearGradient
                                id="background_gradient"
                                x1="0%"
                                y1="0%"
                                x2="100%"
                                y2="100%"
                              >
                                <stop offset="0%" stopColor="#FF8A00" />
                                <stop offset="100%" stopColor="#E52E71" />
                              </linearGradient>
                            </defs>
                            {/* <rect
                              width="100%"
                              height="100%"
                              fill="url(#background_gradient)"
                            /> */}

                            <path
                              d="M21.5 10C21.5 15.2029 16.8449 19.5 11 19.5C5.1551 19.5 0.5 15.2029 0.5 10C0.5 4.79707 5.1551 0.5 11 0.5C16.8449 0.5 21.5 4.79707 21.5 10Z"
                              stroke="url(#paint0_linear_1787_1338)"
                            />

                            <text
                              x="50%"
                              y="55%"
                              textAnchor="middle"
                              alignmentBaseline="middle"
                              fill="black"
                              className="leading-10 text-xs"
                            >
                              {i + 1}
                            </text>

                            <defs>
                              <linearGradient
                                id="paint0_linear_1787_1338"
                                x1="-2.86025e-10"
                                y1="8.91892"
                                x2="21.9873"
                                y2="10.19"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#672CD5" />
                                <stop offset="1" stopColor="#E21185" />
                              </linearGradient>
                            </defs>
                          </svg>
                        );
                      })}
                    </div>
                  </div>
                );
              })}{" "}
            </>
          )}
          {questionType === "visualAnalogTypeQuestion" && (
            <div className="flex gap-14 mt-8">
              <img src={thumbUpIconSelect} className="w-8 cursor-pointer" />
              <img src={thumbDownIcon} className="w-8 cursor-pointer " />
            </div>
          )}
        </div>
      </article>
      <div className=" absolute bottom-0 p-5 pb-8 flex gap-5 w-full">
        <div className="text-grdient-border rounded-xl ">
          <button
            className="rounded-xl w-40 h-8 text-[#672CD5] font-medium "
            style={{ backgroundColor: "rgba(103, 44, 213, 0.15)" }}
            onClick={() => nextQuestionHandler(questionNumberG + 1)}
          >
            Save & Next
          </button>
        </div>
        <div className="text-grdient-border rounded-xl ">
          <button
            className="rounded-xl w-40 h-8 text-[#672CD5] font-medium "
            style={{ backgroundColor: "rgba(103, 44, 213, 0.15)" }}
          >
            Clear Response
          </button>
        </div>
      </div>
    </section>
  );
};

export default ExamQuestionComp;
