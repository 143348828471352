import React, { useState } from "react";
import InputField from "../commonComponents/InputField";
import OtpSubmit from "./OtpSubmit";
import firebase from "../../firebase/firebase";

const RegisterComp = ({
  programData,
  enrolmentData,
  programId,
  enrolmentId,
}) => {
  const [sendOTP, setSendOTP] = useState(true);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  });

  const inputFields = [
    { name: "firstName", label: "First Name" },
    { name: "lastName", label: "Last Name" },
    { name: "email", label: "Email" },
    { name: "phoneNo", label: "Mobile Number" },
    { name: "password", label: "Password" },
    { name: "confirmPassword", label: "Confirm Password" },
  ];

  const validateInput = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return value.length >= 2 ? "" : "Must be at least 2 characters";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email format";
      case "phoneNo":
        const phoneNoLength = value.replace(/\D/g, "").length;
        return phoneNoLength === 10 ? "" : "Must be 10 digits";
      case "password":
        return value.length >= 8 ? "" : "Must be at least 8 characters";
      case "confirmPassword":
        return value === userData.password ? "" : "Passwords do not match";
      default:
        return "";
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    const newValue =
      name === "phoneNo" ? value.replace(/\D/g, "").slice(0, 10) : value;

    setUserData({
      ...userData,
      [name]: newValue,
    });

    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const isFormValid = () => {
    if (userData.firstName === "") {
      return false;
    } else {
      return Object.values(errors).every((error) => error === "");
    }
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    // Check for any remaining errors before proceeding
    // if (!isFormValid()) {
    //   console.log("Validation failed. Please check your input.");
    //   return;
    // }

    // setSpinnerOtpSent(true);
    const phoneNo = "+91" + userData.phoneNo;
    const appVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
    });

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNo, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        // setSentOptSuccess(true);
        setSendOTP(false);

        console.log("OTP has been sent");
      })
      .catch((error) => {
        console.log("SMS not sent", error);
      });
  };

  const inputTypeHandler = (getType) => {
    switch (getType) {
      case "firstName":
      case "lastName":
        return "text";
      case "email":
        // You can use a regular expression for email validation
        return "email";
      case "phoneNo":
        return "number";
      case "password":
      case "confirmPassword":
        return "password";
      default:
        return "";
    }
  };

  return (
    <div className="rounded-lg bg-white bg_shadow m-8 p-6 text-center">
      {sendOTP ? (
        <>
          <div className="text-center font-inter text-20 font-semibold leading-20 tracking-wider">
            <span className="bg-gradient-to-r from-purple-700 to-pink-500 bg-clip-text text-transparent capitalize">
              {programData + " >"} {enrolmentData}
            </span>
          </div>
          <div className="p-6 md:p-0 mdm:p-6 grid md:grid-cols-2 mdm:grid-cols-2 lg:grid-cols-3 gap-8 md:mt-5 mdm:mt-0">
            <InputField
              inputFields={inputFields}
              inputTypeHandler={inputTypeHandler}
              userData={userData}
              inputChangeHandler={inputChangeHandler}
              errors={errors}
            />
          </div>
          <button
            className={`gradient_btn w-44 flex justify-center m-auto md:mt-5 mdm:mt-0 ${
              isFormValid() ? "" : "opacity-50 cursor-not-allowed"
            }`}
            onClick={registerHandler}
            disabled={!isFormValid()}
          >
            Register
          </button>
        </>
      ) : (
        <OtpSubmit
          contactNumber={userData.phoneNo}
          confirmationResult={confirmationResult}
          formData={userData}
          programId={programId}
          enrolmentId={enrolmentId}
        />
      )}

      <div id="sign-in-button" className="hidden"></div>
    </div>
  );
};

export default RegisterComp;
