import React, { useState } from "react";
import CountryFlagSelection from "../commonComponents/CountryFlagSelection";
import { useNavigate } from "react-router-dom";
import LoginWithOtp from "./LoginWithOtp";
import firebase from "../../firebase/firebase";
import BaseURL from "../../BaseURL/BaseURL";

const LoginComp = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    phoneNo: "",
    password: "",
  });
  const [countryCode, setCountryCode] = useState("+91");
  const [loginWithOTP, setLoginWithOTP] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [sendOTP, setSendOTP] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNo") {
      const onlyNumbers = /^[0-9\b]+$/;
      if (value === "" || onlyNumbers.test(value)) {
        setUserData({
          ...userData,
          [name]: value,
        });
      }
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("userData", userData);

    const urlencoded = new URLSearchParams();
    urlencoded.append("phone", countryCode + userData.phoneNo);
    urlencoded.append("password", userData.password);

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    };

    fetch(
      `${BaseURL.baseurl}/api/client/login/phoneAndPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result.payload);
        if (result.message === "Client login successful") {
          localStorage.setItem("authToken", result.payload);
          // navigate("/dashboard");
          alert("Succesfully registered")
        } else {
          alert("Can't login!!! Username or password not match. Try again...");
        }
      })
      .catch((error) => alert(error.message));
  };

  const loginWithOtpSendHandler = () => {
    setLoginWithOTP(true);
    const phoneNo = countryCode + userData.phoneNo;
    console.log("phoneNo", phoneNo);
    const appVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
    });

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNo, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        console.log("confirmationResult", confirmationResult);
        setSendOTP(true);
        console.log("OTP has been sent");
      })
      .catch((error) => {
        console.log("SMS not sent", error);
      });
  };

  return (
    <div className="rounded-lg bg-white bg_shadow p-5 pb-10 xl:w-4/6 md:w-full mdm:w-full  m-auto md:mt-8 xl:mt-12">
      <div className="text-center font-inter text-20 font-semibold leading-20 tracking-wider">
        <span className="bg-gradient-to-r from-purple-700 to-pink-500 bg-clip-text text-transparent">
          Sign in here
        </span>
      </div>

      <section className=" text_field_shadow mt-8 rounded-lg flex items-center gap-1 pl-2">
        <div className=" rounded-full text-gray">
          <CountryFlagSelection setCountryCode={setCountryCode} />
        </div>
        <label className="relative  w-full">
          <input
            type="text"
            name="phoneNo"
            value={userData.phoneNo}
            maxLength={10}
            onChange={handleChange}
            className="block rounded-lg px-2.5 pb-2.5 pt-6 w-full text-sm text-gray-900 bg-gray-50 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 bg-white peer"
            placeholder=" "
          />
          <div
            htmlFor="phoneNo"
            className="absolute te  xt-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Enter Phone
          </div>
        </label>
      </section>
      {loginWithOTP ? (
        <LoginWithOtp
          confirmationResult={confirmationResult}
          formData={countryCode + userData.phoneNo}
          setConfirmationResult={setConfirmationResult}
        />
      ) : (
        <>
          <div className="relative mt-10 text_field_shadow rounded-lg ">
            <input
              id="password"
              type="password"
              name="password"
              value={userData.password}
              onChange={handleChange}
              className="block rounded-lg px-2.5 pb-2.5 pt-6 w-full text-sm text-gray-900 bg-gray-50 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 bg-white peer"
              placeholder=" "
            />
            <label
              htmlFor="password"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
            >
              Enter Password
            </label>
          </div>
          <p
            className="text-xs float-right mt-2 underline text-gradient-md cursor-pointer"
            onClick={loginWithOtpSendHandler}
          >
            Login with OTP
          </p>

          <div className=" m-auto mt-14 text-center">
            <button
              type="submit"
              className="gradient_btn w-44 flex justify-center m-auto"
              onClick={handleSubmit}
            >
              {false ? (
                <svg
                  aria-hidden="true"
                  className="inlinetext-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Sign in"
              )}
            </button>
          </div>
        </>
      )}
      <div id="sign-in-button" className="hidden"></div>
    </div>
  );
};

export default LoginComp;
