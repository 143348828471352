import React, { useState, useEffect } from "react";
import "./welcome.css";
import appImg from "../../assets/images/programImages/appImg.svg";
import jivaImg from "../../assets/images/programImages/jivaImg.svg";
import examImg from "../../assets/images/programImages/exam.svg";
import studentModuleImg from "../../assets/images/programImages/studentModule.svg";
import professionalModuleImg from "../../assets/images/programImages/professionalModule.svg";
import LoginComp from "./LoginComp";
import Paragraph from "../Pragraph/Paragraph";
import Content from "../../assets/Content/Content";
import RegisterComp from "./RegisterComp";
import axios from "axios";
import BaseURL from "../../BaseURL/BaseURL";

const Welcome = () => {
  const steps = [
    "Select Program",
    "About Program",
    "Choose Module",
    "Register",
  ];

  const programImgArr = [
    {
      image: appImg,
      displayName: "Academic Pathway Planning (APP)",
      uniqName: "app",
    },
    {
      image: jivaImg,
      displayName: "AI Age Well Being (JIVA)",
      uniqName: "jiva",
    },
    {
      image: examImg,
      displayName: "Test And Assessments",
      uniqName: "exam",
    },
  ];

  const appModuleImgArr = [
    {
      image: studentModuleImg,
      uniqName: "student",
    },
    {
      image: professionalModuleImg,
      uniqName: "professional",
    },
  ];

  const jivaModuleImgArr = [
    {
      image: studentModuleImg,
      uniqName: "student",
    },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [apiLoaded, setApiLoaded] = useState(true);
  const [programList, setProgramList] = useState([]);
  const [enrolmentList, setEnrolmentList] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [programId, setProgramId] = useState("");
  const [enrolmentId, setEnrolmentId] = useState("");
  const [programDescription, setProgramDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseURL.baseurl}/api/program/getList`
        );
        if (response.data.programList.length !== 0) {
          setProgramList(response.data.programList);
          setApiLoaded(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseURL.baseurl}/api/enrollment/getList?programID=${programId._id}`
        );
        if (response.data.payload.length !== 0) {
          setEnrolmentList(response.data.payload);
          setApiLoaded(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (programId !== "") {
      fetchData();
    }
  }, [programId]);

  const selectProgramHandler = (getProgramID) => {
    setProgramId(getProgramID);
    setSelectedProgram(getProgramID.name);
    setProgramDescription(getProgramID.description);
    setCurrentStep((prev) => prev + 1);
  };

  const selectEnrolmentHandler = (getEnrolmentId) => {
    setEnrolmentId(getEnrolmentId._id);
    setSelectedModule(getEnrolmentId.name);
    setCurrentStep((prev) => prev + 1);
  };

  const goToRegistrationHandler = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <div className="mt-28">
      <div className="grid  xs:grid-cols-1 md:grid-cols-5 gap-0">
        <section className="col-span-2 md:col-span-3  ">
          <div className="text-start font-inter text-20 font-semibold leading-20 tracking-wider p-8">
            <span className="bg-gradient-to-r from-purple-700 to-pink-500 bg-clip-text text-transparent">
              New to Program? Register today
            </span>
          </div>
          <div className="flex justify-between mt-1  w-full md:pl-4 mdm:p-0 ">
            {steps?.map((step, i) => (
              <div
                key={i}
                className={`step-item  ${currentStep === i + 1 && "active"} ${
                  i + 1 < currentStep && "complete"
                } `}
                onClick={() => (currentStep > i ? setCurrentStep(i + 1) : null)}
              >
                <div
                  className={`step ${
                    currentStep > i ? "cursor-pointer" : null
                  }`}
                />
                <p className="text-black text-[10px] mdm:text-xs mt-1.5 ">
                  {step}
                </p>
              </div>
            ))}
          </div>

          {currentStep === 1 && (
            <article className=" p-6  grid grid-cols-3 mt-5 gap-10">
              {programList?.slice(0,3).map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      // backgroundImage: `url("${item.thumbnail.Location}")`,
                      height: "17vw",
                      width: "17vw",

                    }}
                    className="cursor-pointer border bg-gradient-to-r from-purple-700 to-pink-500 capitalize p-1 col-span-1 bg-cover bg-center flex text-white text-center items-center justify-center text-black md:text-base mdm:text-xl rounded-3xl font-semibold hover:opacity-90"
                    onClick={() => selectProgramHandler(item)}
                  >
                    {item.name}
                  </div>
                );
              })}
            </article>
          )}
          {currentStep === 2 && (
            <article className=" mt-10">
              <Paragraph
                description={programDescription + " " + Content.appDescript}
                goToRegistrationHandler={goToRegistrationHandler}
                programId={programId}
                selectedProgram={selectedProgram}
              />
            </article>
          )}
          {currentStep === 3 && (
            <article
              className={` p-6   mt-5 gap-10 
              ${
                enrolmentList?.length > 2
                  ? "grid grid-cols-3"
                  : "flex justify-center"
              }
              `}
            >
              {enrolmentList.length !== 0 ? (
                enrolmentList?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        // backgroundImage: `url(${item.thumbnail.Location})`,
                        height: "17vw",
                        width: "17vw",
                      }}
                      className="cursor-pointer border capitalize  p-1 col-span-1 bg-cover bg-center bg-gradient-to-r from-purple-700 to-pink-500 flex text-center items-center justify-center text-white md:text-base mdm:text-xl rounded-3xl font-semibold hover:opacity-90"
                      onClick={() => selectEnrolmentHandler(item)}
                    >
                      {item.name}
                    </div>
                  );
                })
              ) : (
                <h3 className="text-center  w-full text-gradient-md">
                  Coming soon...
                </h3>
              )}
            </article>
          )}
          {currentStep === 4 && (
            <RegisterComp
              programData={selectedProgram}
              enrolmentData={selectedModule}
              programId={programId._id}
              enrolmentId={enrolmentId}
            />
          )}
          {/* <OtpSubmit/> */}
        </section>

        <section className="col-span-1 md:col-span-2 py-5 md:px-3 mdm:px-5 ">
          <LoginComp />
          {/* <PhoneLoginComp /> */}
        </section>
      </div>
    </div>
  );
};

//https://webhooks.hostinger.com/deploy/67b8b2dbfe8d4ba6d30af1bc802ed902

export default Welcome;
