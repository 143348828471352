import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./styles.css";
import ExamComponent from "../components/examComp";

const ModuleExamPage = () => {
  const { moduleData, moduleAttemptId } = useLocation().state;
  const { _id, duration } = moduleData;

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [detectScreen, setDetectScreen] = useState("");
  const [showInspectWarning, setShowInspectWarning] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isFullScreen) {
        setDetectScreen(
          "You switched to another tab or application while in fullscreen mode!"
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isFullScreen]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      if (isFullScreen) {
        // Prevent default behavior of the context menu
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);

    const handleKeyDown = (event) => {
      // Check if Ctrl+Shift+I is pressed
      if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);

  useEffect(() => {
    toggleFullScreen();
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true);
        })
        .catch((err) => {
          console.error("Failed to enter full screen mode:", err);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false);
        })
        .catch((err) => {
          console.error("Failed to exit full screen mode:", err);
        });
    }
  };

  return (
    <div>
      {/* {!isFullScreen && (
        <button onClick={toggleFullScreen}>Enter Full Screen</button>
      )} */}

      {/* {isFullScreen && (
        <div
          style={{ backgroundColor: "cyan" }}
          className="flex justify-center items-center text-white h-[100vh] w-[100vw]"
        >
          <div>
            <ModuleExamComp />
            <h1 className="text-center font-semibold">Alert!!!</h1>
            <p className="text-center"> {detectScreen}</p>
            <button onClick={toggleFullScreen} className="border p-2">
              Exit Full Screen
            </button>
            {showInspectWarning && (
              <div className="inspect-warning">
                Developer tools are disabled in full-screen mode.
              </div>
            )}
          </div>
        </div>
      )} */}

      {/* <h1 className="text-center font-semibold">Alert!!!</h1>
          <p className="text-center"> {detectScreen}</p>
          <button onClick={toggleFullScreen} className="border p-2">
            Exit Full Screen
          </button> */}
      {/* {showInspectWarning && (
            <div className="inspect-warning">
              Developer tools are disabled in full-screen mode.
            </div>
          )} */}
      <ExamComponent
        moduleId={_id}
        duration={duration}
        moduleAttemptId={moduleAttemptId}
      />
    </div>
  );
};

export default ModuleExamPage;
