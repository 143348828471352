import React from "react";
import "../styles.css";
import { useNavigate } from "react-router-dom";

const StudyStackList = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="grid xl:grid-cols-4 lg:grid-cols-3 mdm:grid-cols-3 gap-x-12 gap-y-8 w-full">
        {[...Array(12)].map((item, i) => {
          return (
            <div
              key={i}
              className="bg-white  w-full rounded-2xl p-4  flex items-center gap-3 cursor-pointer"
              onClick={() => navigate("/study-stack-details")}
            >
              <img
                src="https://cdn.pixabay.com/photo/2016/12/06/01/26/colour-1885352_1280.jpg"
                className=" rounded-md w-10 h-10"
              />
              <h3 className="font-semibold mt-1 text-sm">
                Guage Your Personality
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="10"
                viewBox="0 0 10 17"
                fill="none"
                className="mt-2"
              >
                <path
                  d="M1 1.5L8 8.5L1 15.5"
                  stroke="#292638"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default StudyStackList;
